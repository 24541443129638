const EducationList =[
    {
        id: 1,
        date: '2020',
        title: 'Secondary Education Examination (SEE)',
        college: 'NSS'
    },
    {
        id: 2,
        date: '2021-2023',
        title: 'School Leaving Certificate (SLC)',
        college: 'Kathmandu Model Secondary School'
    },
    {
        id: 3,
        date: '2024-2027',
        title: 'Bachelor of Computer Science',
        college: 'IIMS'
    }


]

export default EducationList;