const PortfolioList = [
    {
        id: 1,
        projectImage: "/images/a.jpg",
        projectTitle: "me with short hair",
        projectDescription: "so i was in 12 th grade at that time"

    },
    {
        id: 2,
        projectImage: "/images/d.jpg",
        projectTitle: "hmm",
        projectDescription: "so i was very bored and clicked it up "
        
    },
    {
        id: 3,
        projectImage: "/images/c.jpg",
        projectTitle: "meh",
        projectDescription: "dunnos fr",
    },
    {
        id: 4,  
        projectImage: "/images/b.jpg", 
        projectTitle: "Covid Resource Nepal",
        projectDescription: "hohohohohoohhohohohoho",
        projectTechnology: "miaaaaaaaaaaaaaaaaaaaaaooooooooooooooooo",
    },
    {
        id: 5,
        projectImage: "/images/3.png",
        projectTitle: "icic",
        projectDescription: "idk why it is here but it is",
    },
    {
        id: 6,
        projectImage: "/images/1.png",
        projectTitle: "araso",
        projectDescription: "so here also idk why it is here butit is",
    },
    {
        id: 7,
        projectImage: "/images/taragaon.jpg",
        projectTitle: "so taragaon museum",
        projectDescription: "si i had went to an art musuem",
    },
    // lofa
    {
        id: 8,
        projectImage: "/images/",
        projectTitle: "well",
        projectDescription: "hmm oh oh ",
    },



]

export default PortfolioList;